import { IEventDispatcherEventMap } from '#/libs/fanfanlo/events/IEventDispatcher';


type Listeners = unknown[]
type ListenMap = Record<any, Listeners>
type OnceListeners = Record<any, Record<any, any>>
export class EventDispatcher {

    private listenDomainMap = new Map()
    private listenMap:ListenMap = {}
    private onceListeners:OnceListeners = {}
    addDomainEventListener<T extends IEventDispatcherEventMap>(domain: any, type: keyof T, cb: T[keyof T], index?: number): void {
        let _domain = this.listenDomainMap.get(domain);
        if(!_domain){
            _domain = {};
            this. listenDomainMap.set(domain, _domain);
        }
        let listeners = _domain[type];
        if(!listeners) listeners = _domain[type] = [];
        if(listeners.indexOf(cb) == -1) listeners.push(cb);
        this.addEventListener(type, cb, index);
    }

    addDomainOnceListener<T extends IEventDispatcherEventMap>(domain: any, type: keyof T, cb: T[keyof T], index?: number): void {
        this.addOnceListener(type, cb, index, domain);
    }

    addEventListener<T extends IEventDispatcherEventMap>(type: keyof T, cb: T[keyof T], index?: number): void {
        if(type === ""){
            try{
                throw new Error("addDomainEventListener error no type")
            }catch (e){ console.log("EventDispatcher2","addDomainEventListener", "err", type, e) }finally {}
        }
        if(typeof cb != "function"){
            try{
                throw new Error("addDomainEventListener error no cb")
            }catch (e){ console.log("EventDispatcher2","addDomainEventListener", "err", cb, e) }finally {}
        }
        let listeners = this.listenMap[type];
        if(!listeners) this.listenMap[type] = listeners = [];
        const _index = listeners.indexOf(cb);
        if(_index > -1)
            listeners.splice(_index, 1);
        if(index == undefined || index < 0)
            index = listeners.length;
        index = Math.max(0, Math.min(index, listeners.length));
        listeners.splice(index, 0, cb);
    }

    addEventListenerOnce<T extends IEventDispatcherEventMap>(type: keyof T, cb: T[keyof T], index?: number): T[keyof T] {
        return this.addOnceListener(type, cb, index, undefined);
    }

    private onceListenerCb <T extends IEventDispatcherEventMap>(type:keyof T, onEvent:T[keyof T], domain?:any) :T[keyof T]{
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        return function(...args:any[]){
            // onEvent(event);
            if(typeof onEvent === 'function'){
                onEvent(...args)
            }else{
                console.error(onEvent);
                console.log(...args);
                throw new Error("onEvent not a function")
            }
            // eslint-disable-next-line prefer-spread,@typescript-eslint/ban-types
            // (onEvent as Function).apply(null, args)
            const listeners = that.onceListeners[type];
            if(domain)
                that.removeDomainEventListener(domain, type, listeners.get(onEvent));
            else
                that.removeEventListener(type, listeners.get(onEvent));
        }.bind(this) as T[keyof T];
    }
    addOnceListener<T extends IEventDispatcherEventMap>(type:keyof T, onEvent:T[keyof T], index?:number, domain?:any):T[keyof T]{
        let listeners = this.onceListeners[type];
        if(!listeners) this.onceListeners[type] = listeners = new Map();

        let fn = this.onceListenerCb<T>(type, onEvent, domain);
        listeners.set(onEvent, fn);
        domain ? this.addDomainEventListener(domain, type, fn, index) : this.addEventListener(type, fn, index);
        return fn;
    }

    dispatchArgs<T extends IEventDispatcherEventMap>(type: keyof T, ...rest: any): boolean {
        let listeners = this.listenMap[type];
        if(!listeners) return false;
        for(let i = 0, len = listeners.length; i < len; i ++){
            listeners[i].apply(null, rest);
        }
        return true;
    }

    dispatchEvent(event?: any): boolean {
        let listeners = this.listenMap[event.type];
        if(!listeners){
            return false;
        }
        event[event._target] = this;
        for(let i = 0, len = listeners.length; i < len; i ++){
            if(event.canceled) return false;
            try{
                listeners[i](event);
            }catch (e){
                console.log("EventDispatcher2","dispatchEvent", "err", e) ;
                console.log("event is", event)
            }finally {}
        }
        return true;
    }

    removeDomain(domain: any): boolean {
        let domainMap = this.listenDomainMap.get(domain);
        if(!domainMap) return false;
        for(let type in domainMap){
            domainMap[type].forEach((cb:any)=> {
                try{
                    this.removeEventListener(type, cb);
                }catch (e){
                    console.log("eventdispatcher2","removedomain", "err", e)
                }finally {}
            }, this);
        }
        this.listenDomainMap.delete(domain);
        return true;
    }

    removeDomainEventListener<T extends IEventDispatcherEventMap>(domain: any, type: keyof T, cb: T[keyof T]): boolean {
        let domainMap = this.listenDomainMap.get(domain);
        if(!domainMap) return false;
        let listeners = domainMap[type];
        if(!listeners) return false;
        let index = listeners.indexOf(cb);
        if(index == -1) return false;
        listeners.splice(index, 1);
        return this.removeEventListener(type, cb);
    }

    removeEventListener<T extends IEventDispatcherEventMap>(type: keyof T, cb: T[keyof T]): boolean {
        let listeners = this.listenMap[type];
        if(!listeners) return false;
        let index = listeners.indexOf(cb);
        if(index == -1) {
            return false;
        } else {
            listeners.splice(index, 1);
            return true;
        }
    }

    willTrigger<T extends IEventDispatcherEventMap>(type: keyof T): boolean {
        return this.listenMap[type] !== undefined && this.listenMap[type].length > 0;
    }


}
