import _ from 'lodash';
import {any} from "prop-types";
import {Event} from "#/libs/fanfanlo/events/Event";

export class DataEvent<T> extends Event{
  public data?: T;

  constructor(type:any, data?:T, cancelable = false) {
    super(type, cancelable);
    this.data = data;
    // this[this._target] = null;

  }


}

export type Event2Any = DataEvent<any>

// Object.defineProperties(Event2.prototype, {
//     _target: {configure:false, value:Symbol('Event2.target'), writable:false}
// });
