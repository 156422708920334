import _ from "lodash";

export class Event{
    public type: string;
    public cancelable: boolean;
    public canceled: boolean;
    public readonly _target: string = "Event.target";
    constructor(type:any, cancelable = false) {
        this.type = type;
        this.cancelable = cancelable;
        this.canceled = false;
    }
    get target() {
        // @ts-ignore
        return this[this._target];
    }

    toString() {
        let res = {};
        _.each(Object.keys(this), (key) => {
            // console.log('key key key', key);
            if (key === 'data') {
                // console.log('key is ', key, this[key])
            }
            ; // @ts-ignore
            res[key] = this[key]
        });
        return res;
    }
}